import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {FormatDateTime, FormatDate} from '@ecosio/components';
import {Icon, Button, Popup} from 'semantic-ui-react';
import {get} from 'lodash';
import moment from 'moment';
import {
  STATUS_NEW,
  STATUS_UPDATED,
  STATUS_ARCHIVED,
  STATUS_READ,
} from '../../shapes/supportedEnvelopeStati';
import DocumentStatusIcons from './DocumentStatusIcons';

export const StatusBadge = ({status}) => {
  switch (status) {
    case STATUS_NEW:
    case STATUS_UPDATED:
      return <Icon name="file alternate outline" />;
    case STATUS_READ:
      return <Icon name="file outline" />;
    case STATUS_ARCHIVED:
      return <Icon name="archive" />;
    default:
      console.warn(`Unknown status ${status}`);
      return null;
  }
};
StatusBadge.propTypes = {
  status: PropTypes.string,
};

const dateRender = (Table, value) => {
  if (value) {
    const isMidnight = moment(value).format('HH:mm') === '00:00';

    if (isMidnight) {
      return (
        <Table.Cell>
          <FormatDate stripTimezone={false} dateString={value} />
        </Table.Cell>
      );
    }
  }
  return (
    <Table.Cell>
      <FormatDateTime stripTimezone={false} dateString={value} />
    </Table.Cell>
  );
};

const dateWithCustomFormatRender = (Table, value, customDateFormat) => {
  if (customDateFormat) {
    return <Table.Cell>{moment(value).format(customDateFormat)}</Table.Cell>;
  } else {
    return (
      <Table.Cell>
        <FormattedMessage id="-" />
      </Table.Cell>
    );
  }
};

const defaultDataRender = (Table, value) => (
  <Table.Cell collapsing textAlign="center">
    {value ? value : <FormattedMessage id="-" />}
  </Table.Cell>
);

const consigneeDataRenderer = (Table, value, values) => {
  const gln = value;
  const name = values?.documentData['envelopeattribute_consigneeName'];
  if (!name) {
    return (
      <Table.Cell collapsing textAlign="center">
        {gln ? gln : <FormattedMessage id="-" />}
      </Table.Cell>
    );
  }

  return (
    <Table.Cell collapsing textAlign="center">
      <div>{name}</div>
      <i style={{fontSize: '11px'}}>
        {gln ? gln : <FormattedMessage id="-" />}
      </i>
    </Table.Cell>
  );
};

const ultimateConsigneeDataRenderer = (Table, value, values) => {
  const gln = value;
  const name = values?.documentData['envelopeattribute_ultimateConsigneeName'];
  if (!name) {
    return (
      <Table.Cell collapsing textAlign="center">
        {gln ? gln : <FormattedMessage id="-" />}
      </Table.Cell>
    );
  }

  return (
    <Table.Cell collapsing textAlign="center">
      <div>{name}</div>
      <i style={{fontSize: '11px'}}>
        {gln ? gln : <FormattedMessage id="-" />}
      </i>
    </Table.Cell>
  );
};

const totalsDataRenderer = (Table, value, values) => {
  const totalOrderedQuantityValue = value;
  const totalOrderedQuantityUnit =
    values?.documentData['envelopeattribute_totalOrderedQuantityUnit'] || '';
  const totalOrderedPackagingValue =
    values?.documentData['envelopeattribute_totalOrderedPackagingValue'] || '';
  const totalOrderedPackagingUnit =
    values?.documentData['envelopeattribute_totalOrderedPackagingUnit'] || '';

  const totalOrderedQuantity = totalOrderedQuantityValue
    ? `${totalOrderedQuantityValue} ${totalOrderedQuantityUnit}`
    : '-';

  const totalOrderedPackaging = totalOrderedPackagingValue
    ? ` / ${totalOrderedPackagingValue} ${totalOrderedPackagingUnit}`
    : ' / -';

  return (
    <Table.Cell collapsing textAlign="center">
      <div>
        {totalOrderedQuantity}
        {totalOrderedPackaging}
      </div>
    </Table.Cell>
  );
};

const getAttributeCell = (customAttribute) => {
  const Cell = {
    id: 'test',
    label: '',
    hidden: false,
    hideable: true,
    sortable: true,
    sort: 2,
    render: defaultDataRender,
  };

  if (customAttribute?.sort) {
    Cell.sort = customAttribute.sort;
  }

  if (customAttribute?.attribute) {
    //this should be documentData. , because the envelope Attributes are saved in documentData map
    Cell.id = `documentData.${customAttribute.attribute}`;
  }

  if (customAttribute?.formField?.type === 'date') {
    if (customAttribute?.customDateFormat) {
      Cell.render = (Table, value) =>
        dateWithCustomFormatRender(
          Table,
          value,
          customAttribute?.customDateFormat
        );
    } else {
      Cell.render = dateRender;
    }
  }

  if (customAttribute?.attribute === 'evelopeattribute_consigneeGln') {
    Cell.render = consigneeDataRenderer;
  }

  if (customAttribute?.attribute === 'evelopeattribute_ultimateConsigneeGln') {
    Cell.render = ultimateConsigneeDataRenderer;
  }

  if (
    customAttribute?.attribute === 'envelopeattribute_totalOrderedQuantityValue'
  ) {
    Cell.render = totalsDataRenderer;
  }
  if (customAttribute?.label) {
    Cell.label = customAttribute.label;
  }

  if (!customAttribute?.attribute) {
    return undefined;
  }

  return Cell;
};

const getGridConfig = ({
  customPropertiesConfig,
  scenario,
  scenarioUuid,
  draftsPerEnvelope = {},
}) => {
  const attributeConfigs = scenario?.attributeConfigs;

  const defaultCfg = {
    idSelector: 'uuid',
    fields: [
      {
        id: 'status',
        label: get(
          scenario?.customerSpecificTranslations,
          'GENERAL_STATUS',
          'GENERAL_STATUS'
        ),
        hidden: false,
        hideable: true,
        sort: 1,
        sortable: true,
        render: (Table, value) => (
          <Table.Cell collapsing textAlign="center">
            <StatusBadge status={value} />
          </Table.Cell>
        ),
      },
      {
        id: 'updatedAt',
        label: get(
          scenario?.customerSpecificTranslations,
          'DATE_CHANGED',
          'DATE_CHANGED'
        ),
        hidden: false,
        hideable: true,
        sort: 2,
        sortable: true,
        render: dateRender,
      },
      {
        id: 'correlatorId',
        label: scenario.correlatorTranslation,
        hidden: false,
        hideable: true,
        sortable: true,
        sort: 3,
      },
      {
        id: 'documents',
        label: get(
          scenario?.customerSpecificTranslations,
          'GENERAL_INCLUDED_DOCUMENTS',
          'GENERAL_INCLUDED_DOCUMENTS'
        ),
        hidden: false,
        hideable: true,
        render: (Table, value, row, error, index, cellIndex) => (
          <Table.Cell collapsing data-spec={`documents-${index}-${cellIndex}`}>
            <DocumentStatusIcons
              documents={value}
              scenario={scenario}
              data-spec={`docs-${index}-${cellIndex}`}
              envelopeUuid={row?.uuid}
              draftsPerEnvelope={draftsPerEnvelope}
            />
          </Table.Cell>
        ),
      },
      {
        id: 'createdAt',
        label: get(
          scenario?.customerSpecificTranslations,
          'DATE_CREATED',
          'DATE_CREATED'
        ),
        hidden: false,
        hideable: true,
        sortable: true,
        render: dateRender,
      },
      {
        id: 'actions',
        label: get(
          scenario?.customerSpecificTranslations,
          'GENERAL_ACTIONS',
          'GENERAL_ACTIONS'
        ),
        hidden: false,
        hideable: false,
        render: (Table, value, row) => (
          <Table.Cell collapsing textAlign="center">
            <Popup
              trigger={
                <Button
                  data-spec={`open-${row.uuid}`}
                  as={Link}
                  to={`/scenario/${scenarioUuid}/envelope/${row.uuid}`}
                  size="small"
                  primary
                  compact
                  icon>
                  <Icon name="eye" />
                </Button>
              }
              content={<FormattedMessage id="GENERAL_SHOW_DETAILS" />}
              hideOnScroll
              on="hover"
              position="left center"
            />
          </Table.Cell>
        ),
      },
    ],
  };

  attributeConfigs.forEach((attributeConfig) => {
    const isInDefaultConfigIndex = defaultCfg.fields.findIndex(
      (item) => item.id === attributeConfig.attribute
    );
    if (isInDefaultConfigIndex === -1 && attributeConfig?.showInEnvelopeTable) {
      if (getAttributeCell(attributeConfig)) {
        defaultCfg.fields.push(getAttributeCell(attributeConfig));
      } else {
        console.warn(`attribute: ${attributeConfig.attribute} is not defined`);
      }
    }

    if (isInDefaultConfigIndex !== -1) {
      //customDateFormat for static field based on Config
      //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/796
      defaultCfg.fields[isInDefaultConfigIndex] = {
        ...defaultCfg.fields[isInDefaultConfigIndex],
        render: !(
          attributeConfig?.formField?.type === 'date' &&
          attributeConfig?.customDateFormat
        )
          ? defaultCfg.fields[isInDefaultConfigIndex].render
          : (Table, value) =>
              dateWithCustomFormatRender(
                Table,
                value,
                attributeConfig?.customDateFormat
              ),
      };
    }
  });
  if (customPropertiesConfig) {
    return {
      ...defaultCfg,
      fields: [...defaultCfg.fields, ...customPropertiesConfig],
    };
  }

  return defaultCfg;
};

export default getGridConfig;
