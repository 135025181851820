import {QueryClient} from '@tanstack/react-query';
import {isAxiosError} from 'axios';

const queryRetries = 3;
const retryableErrorCodes = [502, 503, 504];

export default function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // I don't think that we really want that
        refetchOnWindowFocus: false,
        retry: (count, error) => {
          return (
            isAxiosError(error) &&
            count <= queryRetries &&
            retryableErrorCodes.includes(error.response?.status)
          );
        },
      },
    },
  });
}
